import { connect } from "react-redux"
import {
  submitApplePayAsync,
  applePayButtonClicked,
} from "highline/redux/actions/apple_pay_actions"
import { cartAddAsGiftAsync } from "highline/redux/actions/cart_actions"
import { fetchCountries } from "highline/redux/actions/location_actions"

import ApplePayButton from "highline/components/apple_pay_button"

const mapStateToProps = (_state, ownProps) => ({
  isTermsVisible: ownProps.isTermsVisible,
})

const mapDispatchToProps = (dispatch) => {
  return {
    applePayButtonClicked: () => {
      dispatch(cartAddAsGiftAsync())
      dispatch(applePayButtonClicked())
      dispatch(submitApplePayAsync())
    },
    onMount: () => {
      dispatch(fetchCountries())
    },
  }
}

const ApplePayContainer = connect(mapStateToProps, mapDispatchToProps)(ApplePayButton)

export default ApplePayContainer
