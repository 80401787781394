import { Base64 } from "js-base64"
import { fromJS, Map } from "immutable"
import { getShippingRateForRequest } from "highline/redux/helpers/checkout_helper"

export const convertAvailableShippingRates = (shippingRate) =>
  Map({
    amount: shippingRate.get("totalNumeric"),
    detail: getShippingRateDetail(shippingRate.get("name")),
    identifier: shippingRate.get("code"),
    label: getShippingRateLabel(shippingRate.get("name")),
  })

export const getCompleteCheckoutDataForRequest = (
  event,
  shippingRate,
  signifydSessionId,
  ga4ClientId
) => {
  const shippingContact = event.payment.shippingContact

  return fromJS({
    acceptPrivacyPolicy: true,
    acceptTermsOfService: true,
    address: getAddressForRequest(shippingContact),
    email: shippingContact.emailAddress,
    shippingRate: getShippingRateForRequest(shippingRate),
    applePay: getApplePayAttributes(event),
    signifydSessionId,
    ga4ClientId,
  })
}

const getShippingRateDetail = (name) => {
  if (name.includes(":")) {
    return name.split(":")[1].trim()
  } else {
    return name.match(/([\w]+)[ ]+([\w\d\- ]+)/)[2]
  }
}

const getShippingRateLabel = (name) => {
  if (name.includes(":")) {
    return name.split(":")[0].trim()
  } else {
    return name.match(/([\w]+)[ ]+([\w\d\- ]+)/)[1]
  }
}

const getAddressForRequest = (contactInfo) => {
  const {
    addressLines,
    administrativeArea,
    locality,
    countryCode,
    postalCode,
    givenName,
    familyName,
    phoneNumber,
  } = contactInfo
  return {
    address1: addressLines[0],
    address2: addressLines.slice(1).join(" "), // join rest of the lines together
    city: locality,
    countryCode: countryCode.toUpperCase(),
    firstName: givenName,
    lastName: familyName,
    phone: phoneNumber ? phoneNumber : "000-000-0000",
    postalCode,
    regionCode: administrativeArea,
  }
}

const getApplePayAttributes = (event) => {
  const { givenName, familyName } = event.payment.shippingContact
  return {
    billAddress: getAddressForRequest(event.payment.billingContact),
    name: `${givenName} ${familyName}`,
    token: Base64.encode(JSON.stringify(event.payment.token.paymentData)),
  }
}

/**
 * Fill the errors array with proper ApplePayError object based on
 * the address errors received from the flatiron API.
 *
 * @param errors
 * @param errorData
 */
export const fillAddressErrorFromApi = (errors = [], errorData) => {
  const addressErrors = errorData.getIn(["errors", "address"])

  if (!addressErrors) {
    return
  }

  const addressErrorType =
    addressErrors.get("type") === "ship_address"
      ? "shippingContactInvalid"
      : "billingContactInvalid"

  if (addressErrors.get("state")) {
    errors.push(
      new window.ApplePayError(addressErrorType, "administrativeArea", "State/Province is invalid")
    )
  }

  if (addressErrors.get("address1") || addressErrors.get("address2")) {
    errors.push(
      new window.ApplePayError(addressErrorType, "addressLines", "Address line is too long")
    )
  }
}

export const addShippingContactErrors = (
  errors = [],
  availableCountryCodes,
  selectedCountryCode
) => {
  if (!availableCountryCodes.includes(selectedCountryCode.toUpperCase())) {
    errors.push(
      new window.ApplePayError(
        "shippingContactInvalid",
        "country",
        "Shipping is not available for selected country"
      )
    )
  }
}
