import React from "react"
import classNames from "classnames"
import PropTypes from "prop-types"
import { ApplePayIcon } from "highline/components/icons"
import Button from "highline/components/button"
import TermsAndPrivacy from "highline/components/terms_and_privacy/terms_and_privacy_summary"
import styles from "highline/styles/components/apple_pay_button.module.css"

class ApplePayButton extends React.PureComponent {
  static propTypes = {
    applePayButtonClicked: PropTypes.func,
    isTermsVisible: PropTypes.bool,
    onMount: PropTypes.func,
  }

  static defaultProps = {
    applePayButtonClicked: () => {},
    isTermsVisible: true,
    onMount: () => {},
  }

  componentDidMount() {
    this.props.onMount()
  }

  render() {
    const { applePayButtonClicked, isTermsVisible } = this.props

    return (
      <div className={classNames("apple-pay-button-component", styles.component)}>
        <Button
          ariaLabel="Pay with Apple Pay"
          align="stretch"
          className={styles.applePayButton}
          onClick={applePayButtonClicked}
        >
          <ApplePayIcon className={styles.icon} />
        </Button>
        {isTermsVisible && (
          <TermsAndPrivacy
            copy={(content) => (
              <div>By selecting &quot;Apple Pay&quot;, you agree to our {content}.</div>
            )}
            indicateUpdate
            layout="light"
            openInModal={false}
          />
        )}
      </div>
    )
  }
}

export default ApplePayButton
