import React, { useEffect, useCallback, useMemo } from "react"
import PropTypes from "prop-types"
import { useDispatch, useSelector } from "react-redux"
import { List, Map } from "immutable"
import Script from "next/script"
import getConfig from "highline/config/application"
import {
  shouldEnableApplePay,
  shouldEnablePaypalExpress,
} from "highline/utils/payment_options_helper"
import { contentfulComponentClicked } from "highline/redux/actions/contentful_actions"
import LoadingButton from "highline/components/loading_button"
import LoadingCurtain from "highline/components/loading_curtain"
import { getClientSideLink } from "highline/utils/link"
import LineItem from "highline/components/cart/line_item"
import { CSSTransition } from "react-transition-group"
import { renderContentfulComponent } from "highline/utils/contentful/component_helper"
import { getObjectByFirstField, getField } from "highline/utils/contentful/contentful_helper"
import PromoField from "highline/components/cart/promo_field"
import Spinner from "highline/components/spinner"
import SuggestedItemTile from "highline/components/cart/suggested_item_tile"
import NotificationList from "highline/components/cart/notification_list"
import ApplePayContainer from "highline/containers/apple_pay_container"
import PaypalButton from "highline/components/paypal_button"
import Gift from "highline/components/cart/gift"
import AffirmEstimate from "highline/components/affirm/affirm_estimate"
import { containsGiftCard, getLineItemProps } from "highline/utils/cart_helper"
import initAffirm, { getNumericPrice } from "highline/utils/affirm"
import Cta from "highline/components/cta"
import { paths } from "highline/utils/navigate"
import { isFeatureEnabled } from "highline/utils/abtasty_helper"

import classNames from "classnames"
import styles from "highline/styles/components/cart/cart.module.css"
import {
  useSelectAdyenMerchantSignature,
  useSelectBundleDiscountTotal,
  useSelectBundleDiscountTotalNumeric,
  useSelectCartContentfulData,
  useSelectEstimatedStoreCredit,
  useSelectEstimatedStoreCreditNumeric,
  useSelectEstimatedTotal,
  useSelectGiftNote,
  useSelectIsGift,
  useSelectIsLoading,
  useSelectIsNavigatingToCheckout,
  useSelectIsPromoFieldOpen,
  useSelectIsReady,
  useSelectLineItems,
  useSelectMarkdown,
  useSelectNewLineItemLoading,
  useSelectPaypalInputs,
  useSelectPromo,
  useSelectPromoCodeDetails,
  useSelectPromoError,
  useSelectSubtotal,
  useSelectSuggestedItem,
} from "highline/selectors/cart/cart_selectors"
import { useSelectIsLoggedIn } from "highline/selectors/auth/auth_selectors"
import {
  addLineItemsToCartAsync,
  addToWishlistAsync,
  cartAddAsGiftAsync,
  cartPageOpened,
  cartGiftNoteChanged,
  cartIsGiftInputToggled,
  cartPaypalButtonClicked,
  cartPromoCodeInputChanged,
  cartPromoFieldToggled,
  checkoutAsync,
  deleteLineItemFromCartAsync,
  guestCheckoutClicked,
  removeLineItemsFromCartAsync,
  submitAddPromoCodeAsync,
  submitRemovePromoCodeAsync,
  suggestedItemDismissed,
  suggestedItemProductPreviewClicked,
  loadCartAsync,
  suggestedItemDisplayedInCart,
} from "highline/redux/actions/cart_actions"
import { useSelectNotifications } from "highline/selectors/cart_notifications/cart_notifications_selectors"
import { useSelectPromotionExclusions } from "highline/selectors/promotion/promotion_selectors"
import {
  productPreviewSetNewProduct,
  productPreviewFetchAsync,
} from "highline/redux/actions/product_preview_actions"
import {
  loadAsync as loadNotificationsAsync,
  dismissAsync as dismissNotificationAsync,
  notificationMessageClicked,
} from "highline/redux/actions/cart_notification_actions"
import { savedItemCtaClickedAsync } from "highline/redux/actions/saved_items_actions"

const { affirmPaymentEnabled, enableGifting, isGuestCheckoutEnabled } = getConfig()
const TRANSITION_TIMEOUT = 300
const cartLayout = "cart_full_page"

const nonZeroAmount = (value) => Boolean(value && value > 0)

const pantsLink = getClientSideLink("/shop/clothing/pants")
const shirtsLink = getClientSideLink("/shop/clothing/shirts")
const newArrivalsLink = getClientSideLink("/shop/new-arrivals")
const suitsLink = getClientSideLink("/shop/suits-and-blazers")

const Cart = ({ guestCheckoutText, onMount }) => {
  const dispatch = useDispatch()

  const contentfulData = useSelector(useSelectCartContentfulData)
  const adyenMerchantSignature = useSelector(useSelectAdyenMerchantSignature)
  const bundleDiscountTotal = useSelector(useSelectBundleDiscountTotal)
  const bundleDiscountTotalNumeric = useSelector(useSelectBundleDiscountTotalNumeric)
  const estimatedStoreCredit = useSelector(useSelectEstimatedStoreCredit)
  const estimatedStoreCreditNumeric = useSelector(useSelectEstimatedStoreCreditNumeric)
  const estimatedTotal = useSelector(useSelectEstimatedTotal)
  const giftNote = useSelector(useSelectGiftNote) ?? null
  const isGift = useSelector(useSelectIsGift) ?? false
  const isLoading = useSelector(useSelectIsLoading) ?? true
  const isLoggedIn = useSelector(useSelectIsLoggedIn)
  const isNavigatingToCheckout = useSelector(useSelectIsNavigatingToCheckout) ?? false
  const isPromoFieldOpen = useSelector(useSelectIsPromoFieldOpen) ?? false
  const isReady = useSelector(useSelectIsReady)
  const lineItems = useSelector(useSelectLineItems) ?? List()
  const markdown = useSelector(useSelectMarkdown) ?? Map()
  const newLineItemLoading = useSelector(useSelectNewLineItemLoading)
  const notifications = useSelector(useSelectNotifications)
  const paypalInputs = useSelector(useSelectPaypalInputs)
  const promo = useSelector(useSelectPromo)
  const promoCodeDetails = useSelector(useSelectPromoCodeDetails) ?? Map()
  const promotionExclusions = useSelector(useSelectPromotionExclusions)
  const promoError = useSelector(useSelectPromoError)
  const subtotal = useSelector(useSelectSubtotal)
  const suggestedItem = useSelector(useSelectSuggestedItem) ?? Map()
  const hasLineItems = useMemo(() => !lineItems.isEmpty(), [lineItems])
  const shouldShowSuggestedItem = useMemo(
    () => !suggestedItem.isEmpty() && hasLineItems,
    [suggestedItem, hasLineItems]
  )

  const cartContentfulData = getField(contentfulData, "content")
  const cartFooterData =
    getField(getObjectByFirstField(cartContentfulData, "Cart Footer"), "content") ?? List()
  const cartLeftColumn =
    getField(getObjectByFirstField(cartContentfulData, "Cart Left Column"), "content") ?? List()
  const cartRightColumn =
    getField(getObjectByFirstField(cartContentfulData, "Cart Right Column"), "content") ?? List()
  const emptyCartFooterData =
    getField(getObjectByFirstField(cartContentfulData, "Empty Cart Footer"), "content") ?? List()

  function contentfulAnalyticCallbackFn(contentType, target, contentId) {
    dispatch(contentfulComponentClicked(contentType, target, contentId))
  }

  const handleLoad = useCallback(() => {
    dispatch(cartPageOpened())
    dispatch(loadCartAsync())
  }, [dispatch])

  function handleAddToWishlist(sku) {
    dispatch(addToWishlistAsync(sku))
  }

  function handleCartGiftNoteChanged(event, giftingEdited) {
    dispatch(cartGiftNoteChanged(giftingEdited, event.target.value))
  }

  function handleCartIsGiftInputToggle(giftingEdited, giftNote, isGift) {
    const note = isGift ? giftNote : null

    dispatch(cartIsGiftInputToggled(giftingEdited, isGift))
    dispatch(cartGiftNoteChanged(giftingEdited, note))
  }

  function handleCheckout(event) {
    event.preventDefault()

    dispatch(checkoutAsync())
  }

  function handleGuestCheckoutClick() {
    dispatch(guestCheckoutClicked())
  }

  function handleLineItemDecrement(lineItems, onComplete) {
    dispatch(removeLineItemsFromCartAsync(lineItems, onComplete))
  }

  function handleLineItemDelete(sku) {
    dispatch(deleteLineItemFromCartAsync(sku))
  }

  function handleLineItemDetailsClicked(slug, selectedOptions) {
    dispatch(productPreviewSetNewProduct(slug, selectedOptions))
    dispatch(productPreviewFetchAsync(slug, selectedOptions, false, null, "pdp modal"))
  }

  function onLineItemIncrement(lineItems, onComplete) {
    dispatch(addLineItemsToCartAsync(lineItems, onComplete, true))
  }

  function handleMessageClick(notificationId) {
    dispatch(notificationMessageClicked(notificationId))
  }

  function handleNotificationDismissal(notificationId) {
    dispatch(dismissNotificationAsync(notificationId))
  }

  function handlePaypalButtonClick() {
    dispatch(cartAddAsGiftAsync())
    dispatch(cartPaypalButtonClicked())
  }

  function handlePromoCodeInputChange(event) {
    dispatch(cartPromoCodeInputChanged(event.target.name, event.target.value))
  }

  function handlePromoCodeAddSubmit(event) {
    event.preventDefault()
    dispatch(submitAddPromoCodeAsync())
  }

  function handlePromoCodeRemoveSubmit(event) {
    event.preventDefault()
    dispatch(submitRemovePromoCodeAsync())
  }

  function handleSuggestedItemClick(suggestedItem) {
    dispatch(suggestedItemProductPreviewClicked(suggestedItem))
  }

  function handleSuggestedItemDismiss(suggestedItem) {
    dispatch(suggestedItemDismissed(suggestedItem))
  }

  function handleTogglePromoField() {
    dispatch(cartPromoFieldToggled())
  }

  function handleUnauthenticatedSaveItemClick(slug, options) {
    dispatch(savedItemCtaClickedAsync(slug, options, false, "cart"))
  }

  useEffect(() => {
    onMount()
  }, [onMount])

  useEffect(() => {
    if (isReady) {
      handleLoad()
    }
  }, [isReady, handleLoad])

  useEffect(() => {
    if (shouldShowSuggestedItem) {
      dispatch(suggestedItemDisplayedInCart(suggestedItem))
    }
    // It's needed because `suggestedItem` will be a new item each time reducer adds it
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, shouldShowSuggestedItem])

  useEffect(() => {
    if (hasLineItems) {
      dispatch(loadNotificationsAsync())
    }
  }, [hasLineItems, dispatch])

  const showApplePayButton = shouldEnableApplePay()
  const showGuestCheckoutButton = isGuestCheckoutEnabled && !isLoggedIn
  const showPaypalButton = shouldEnablePaypalExpress(isLoggedIn)
  const showGuestCheckoutOptions = showPaypalButton || showApplePayButton || showGuestCheckoutButton
  const isStickyGTCEnabled = isFeatureEnabled("Sticky-GTC")

  const renderCheckoutButton = () => (
    <form className={styles.checkoutForm} method="post" onSubmit={handleCheckout}>
      <LoadingButton
        aria-label="Proceed to Checkout Flow"
        className="checkout-button"
        align="stretch"
        loading={isNavigatingToCheckout}
      >
        Checkout
      </LoadingButton>
    </form>
  )

  const renderLineItems = () => (
    <div className={classNames(styles.lineItems, styles[cartLayout])}>
      {notifications?.size > 0 && (
        <div className={styles.notifications}>
          <NotificationList
            notifications={notifications}
            onDismissal={handleNotificationDismissal}
            onMessageClick={handleMessageClick}
          />
        </div>
      )}
      {lineItems.isEmpty() && (
        <CSSTransition
          classNames={{
            enter: styles.emptyEnter,
            enterActive: styles.emptyEnterActive,
          }}
          leave="false"
          timeout={TRANSITION_TIMEOUT}
          in={lineItems.isEmpty()}
        >
          <div
            key="empty"
            className={classNames(styles.empty, styles[cartLayout], {
              [styles.withEmptyCartFooterData]: !emptyCartFooterData.isEmpty(),
            })}
          >
            <div className={styles.emptyText}>Your Cart is Empty</div>
            <div className={styles.emptyDescriptionText}>
              It would look nicer with some new clothes in it.
            </div>
            <Cta
              align="inline"
              layout="primary-outline"
              href={pantsLink.get("as")}
              className={styles.emptyCta}
            >
              Shop Pants
            </Cta>
            <Cta
              align="inline"
              layout="primary-outline"
              href={shirtsLink.get("as")}
              className={styles.emptyCta}
            >
              Shop shirts
            </Cta>
            <Cta
              align="inline"
              layout="primary-outline"
              href={newArrivalsLink.get("as")}
              className={styles.emptyCta}
            >
              Shop New Arrivals
            </Cta>
            <Cta
              align="inline"
              layout="primary-outline"
              href={suitsLink.get("as")}
              className={styles.emptyCta}
            >
              Shop Suits & Blazers
            </Cta>
          </div>
        </CSSTransition>
      )}

      <ul className={classNames("cart-line-items", styles.lineItemsList)}>
        {!lineItems.isEmpty() && (
          <>
            <CSSTransition
              classNames={{
                exit: styles.lineItemExit,
                exitActive: styles.lineItemExitActive,
              }}
              enter={false}
              timeout={TRANSITION_TIMEOUT}
              in={!lineItems.isEmpty()}
            >
              <div className={(styles.cardWrapper, styles.card)}>
                <div
                  className={classNames(
                    styles.mobileCheckoutButton,
                    isStickyGTCEnabled && styles.mobileCheckoutButtonSticky
                  )}
                >
                  {renderCheckoutButton()}
                </div>
                <h2 className={styles.cardTitle}>Your Items</h2>
                {lineItems.map((lineItem, index) => (
                  <li className={styles.lineItem} key={`${lineItem.get("sku")}-${index}`}>
                    <LineItem
                      {...getLineItemProps(lineItem)}
                      isLoggedIn={isLoggedIn}
                      isPromoCodeApplied={promoCodeDetails.get("isPromoCodeApplied")}
                      onRemove={handleLineItemDelete}
                      onDelete={handleLineItemDelete}
                      onDecrement={handleLineItemDecrement}
                      onIncrement={onLineItemIncrement}
                      onDetailsClicked={handleLineItemDetailsClicked}
                      onUnauthenticatedSaveItemClick={handleUnauthenticatedSaveItemClick}
                      onWishlist={handleAddToWishlist}
                      layout={cartLayout}
                      shouldShowModal
                    />
                  </li>
                ))}
              </div>
            </CSSTransition>
          </>
        )}

        {newLineItemLoading && (
          <li
            className={classNames(
              styles.lineItemLoading,
              lineItems.isEmpty() ? styles.lineItemLoadingEmpty : null
            )}
          >
            <Spinner className={styles.spinner} />
          </li>
        )}
      </ul>
    </div>
  )

  const renderFooter = () => (
    <footer className={classNames(styles.footer, styles[cartLayout])}>
      <div className={classNames(styles.cardWrapper, styles[cartLayout])}>
        <div className={classNames(styles.card, styles.max_width)}>
          <h2 className={styles.cardTitle} tabIndex="-1">
            Order Summary
          </h2>
          <div className={styles.footerItem}>
            {enableGifting && (
              <Gift
                giftNote={giftNote}
                isGift={isGift}
                onCartGiftNoteChanged={handleCartGiftNoteChanged}
                onCartIsGiftInputToggle={handleCartIsGiftInputToggle}
              />
            )}

            <PromoField
              className={classNames(styles.promoCodeForm, styles.footerItem)}
              error={promoError}
              isPromoCodeApplied={promoCodeDetails.get("isPromoCodeApplied")}
              isPromoFieldOpen={isPromoFieldOpen}
              isLoading={promoCodeDetails.get("isLoading")}
              code={promoCodeDetails.get("code")}
              onAddPromoSubmit={handlePromoCodeAddSubmit}
              onTogglePromoField={handleTogglePromoField}
              onRemovePromoSubmit={handlePromoCodeRemoveSubmit}
              onInputChange={handlePromoCodeInputChange}
              placeholder={"Apply a promo code"}
            />

            <dl className={styles.subtotal}>
              <dt>Subtotal</dt>
              <dd>{subtotal}</dd>
            </dl>

            {nonZeroAmount(markdown.get("numeric")) && (
              <dl className={styles.markdown}>
                <dt>Markdown Discount</dt>
                <dd>{markdown.get("display")}</dd>
              </dl>
            )}

            {nonZeroAmount(bundleDiscountTotalNumeric) && (
              <dl className={styles.bundleDiscount}>
                <dt>Bundle Discount</dt>
                <dd>{bundleDiscountTotal}</dd>
              </dl>
            )}

            {promo && !promo.isEmpty() && (
              <dl className={styles.appliedPromo}>
                <dt>Promo</dt>
                <dd>{promo.get("total")}</dd>
              </dl>
            )}

            {estimatedStoreCredit && nonZeroAmount(estimatedStoreCreditNumeric) && (
              <dl className={styles.storeCredit}>
                <dt>Store Credit</dt>
                <dd>{estimatedStoreCredit}</dd>
              </dl>
            )}
          </div>
          <dl className={classNames(styles.footerItem, styles.total)}>
            <dt>
              Total <span className={styles.shippingMessage}>— Free U.S. Shipping & Returns</span>
            </dt>
            <dd>{estimatedTotal}</dd>
          </dl>

          {!containsGiftCard(lineItems) && affirmPaymentEnabled && (
            <div className={classNames(styles.affirm)}>
              <AffirmEstimate source="cart" totalAmount={getNumericPrice(estimatedTotal)} />
            </div>
          )}
          {
            <div className={classNames(isStickyGTCEnabled && styles.desktopCheckoutButton)}>
              {renderCheckoutButton()}
            </div>
          }
          {showGuestCheckoutOptions && (
            <div className={classNames(styles.guestCheckoutOptions)}>
              <span>or</span>
              <h4>Want to checkout quicker?</h4>
            </div>
          )}
          {showGuestCheckoutButton && (
            <div className={styles.checkoutForm}>
              <Cta
                aria-label="Proceed to Guest Checkout Flow"
                className={styles.guestCheckoutButton}
                layout="primary-outline"
                href={paths.get("singlePageCheckout")}
                onClick={handleGuestCheckoutClick}
              >
                {guestCheckoutText}
              </Cta>
            </div>
          )}
          {showPaypalButton && (
            <div className={styles.checkoutForm}>
              <PaypalButton
                adyenMerchantSignature={adyenMerchantSignature}
                onPaypalButtonClick={handlePaypalButtonClick}
                paypalInputs={paypalInputs}
              />
            </div>
          )}

          {showApplePayButton && (
            <div className={styles.checkoutForm}>
              <ApplePayContainer />
            </div>
          )}
        </div>
        {!cartRightColumn.isEmpty() &&
          cartRightColumn.map((component, index) =>
            renderContentfulComponent(
              component,
              contentfulAnalyticCallbackFn,
              `cart-right-column-${index}`
            )
          )}
      </div>
    </footer>
  )

  if (isLoading) {
    return <LoadingCurtain delay={1000} layout="light" show={isLoading} />
  }

  return (
    <div
      className={classNames("component", "cart-component", styles.component, styles[cartLayout])}
    >
      {affirmPaymentEnabled && (
        <Script id="loadAffirm" strategy="lazyOnload">
          {initAffirm()}
        </Script>
      )}

      <div className={styles.contentWrapper}>
        <div>
          {renderLineItems()}
          {shouldShowSuggestedItem && (
            <div className={styles.recommenedItems}>
              <SuggestedItemTile
                item={suggestedItem}
                onClickPrice={handleSuggestedItemClick}
                onRemove={handleSuggestedItemDismiss}
                promotionExclusions={promotionExclusions}
              />
            </div>
          )}
          {!lineItems.isEmpty() && !cartLeftColumn.isEmpty() && (
            <div className={styles.cartLeftColumn}>
              {cartLeftColumn.map((component, index) =>
                renderContentfulComponent(
                  component,
                  contentfulAnalyticCallbackFn,
                  `cart-left-column-${index}`
                )
              )}
            </div>
          )}
          {lineItems.isEmpty() && !emptyCartFooterData.isEmpty() && (
            <div className={styles.emptyCartFooter}>
              {emptyCartFooterData.map((component, index) =>
                renderContentfulComponent(
                  component,
                  contentfulAnalyticCallbackFn,
                  `empty-cart-footer-${index}`
                )
              )}
            </div>
          )}
        </div>
        {!lineItems.isEmpty() && renderFooter()}
      </div>

      {!lineItems.isEmpty() && !cartFooterData.isEmpty() && (
        <div className={styles.cartFooterWrapper}>
          {cartFooterData.map((component, index) =>
            renderContentfulComponent(
              component,
              contentfulAnalyticCallbackFn,
              `cart-footer-${index}`
            )
          )}
        </div>
      )}
    </div>
  )
}

Cart.propTypes = {
  guestCheckoutText: PropTypes.string,
  onMount: PropTypes.func,
}

Cart.defaultProps = {
  guestCheckoutText: "Continue as a Guest",
  onMount: () => {},
}

export default Cart
