import React from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { Map } from "immutable"
import ImmutablePropTypes from "react-immutable-proptypes"
import LineItem from "highline/components/cart/line_item"
import Button from "highline/components/button"
import { generateLink } from "highline/redux/helpers/filters_helper"
import { shouldExcludeProgram } from "highline/utils/promo_auto_apply_helper"
import styles from "highline/styles/components/cart/add_on_tile.module.css"

const SuggestedItemTile = ({ item, onClickPrice, onRemove, promotionExclusions }) => {
  const link = generateLink(
    item.get("productSlug"),
    item.getIn(["color", "name"]),
    Map(),
    item.get("isGiftCard"),
    item.get("isBundle")
  )

  const isGiftCard = item.get("isGiftCard")

  const swatchExcludedFromPromo =
    isGiftCard ||
    shouldExcludeProgram(
      item.get("productSlug"),
      item.getIn(["color", "name"]),
      promotionExclusions.toJS()
    )

  return (
    <div
      className={classNames("component", "add-on-tile-component", styles.component)}
      data-product-id={item.get("id")}
      data-pod-id={item.get("podId")}
      data-strategy-id={item.get("strategyId")}
      data-cnstrc-item="recommendation"
      data-cnstrc-item-id={item.get("id")}
      data-cnstrc-strategy-id={item.get("strategyId")}
      data-cnstrc-item-name={item.get("name")}
      data-cnstrc-item-variation-id={item.get("color").get("name")}
    >
      <div className={styles.card}>
        <h2 className={styles.cardTitle} tabIndex="-1">
          You may also like
        </h2>
        <div className={styles.lineItemWrapper}>
          <LineItem
            link={link.get("as")}
            name={item.get("name")}
            description={item.get("description")}
            onRemove={() => onRemove(item)}
            thumbnailSrc={item.get("image")}
            inStock
          />
          <Button
            align="stretch"
            ariaLabel={`Open Quick Shop for ${item.get("name")}`}
            layout="secondary-outline"
            onClick={() => {
              onClickPrice(item)
            }}
            size="small"
            data-cnstrc-btn="add_to_cart"
          >
            Add to Cart -
            {(item.get("isOnSale") || item.get("promoPrice")) && !swatchExcludedFromPromo && (
              <span className={styles.fullPrice}>{item.get("price")}</span>
            )}
            <span
              className={
                (item.get("isOnSale") || item.get("promoPrice")) && !swatchExcludedFromPromo
                  ? styles.salePrice
                  : styles.regularPrice
              }
            >
              {item.get("promoPrice") && !swatchExcludedFromPromo
                ? item.get("promoPrice")
                : item.get("price")}
            </span>
          </Button>
        </div>
      </div>
    </div>
  )
}

SuggestedItemTile.propTypes = {
  item: ImmutablePropTypes.mapContains({
    color: ImmutablePropTypes.map,
    description: PropTypes.string,
    isGiftCard: PropTypes.bool,
    name: PropTypes.string,
    price: PropTypes.string,
    productSku: PropTypes.string,
    productSlug: PropTypes.string,
    promoPrice: PropTypes.string,
  }).isRequired,
  onClickPrice: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  promotionExclusions: ImmutablePropTypes.map,
}

export default SuggestedItemTile
