import ActionTypes from "highline/redux/action_types"
import * as CheckoutApi from "highline/api/checkout_api"
import { verify } from "highline/api/apple_pay_api"
import { fromJS } from "immutable"
import {
  convertAvailableShippingRates,
  fillAddressErrorFromApi,
  getCompleteCheckoutDataForRequest,
  addShippingContactErrors,
} from "highline/redux/helpers/apple_pay_helper"
import * as Cookies from "highline/utils/cookies"

const APPLE_PAY_VERSION = 3
const APPLE_PAY_COUNTRY_CODE = "US"
const APPLE_PAY_CURRENCY_CODE = "USD"
const APPLE_PAY_SUPPORTED_NETWORKS = ["visa", "masterCard", "amex", "discover"]

// placeholder values
const ADDRESS_PLACEHOLDER = "APPLE PAY PLACEHOLDER DO NOT SHIP"
const PERSON_NAME_PLACEHOLDER = "_PLACEHOLDER"
const PHONE_PLACEHOLDER = "000-000-0000"

/* eslint-disable sort-keys */
const sessionStarted = () => ({
  type: ActionTypes.APPLE_PAY_SESSION_STARTED,
})

const sessionFailed = (error) => ({
  type: ActionTypes.APPLE_PAY_SESSION_FAILED,
  error,
})

const sessionCreated = (applePaySession) => ({
  type: ActionTypes.APPLE_PAY_SESSION_CREATED,
  session: applePaySession,
})

const merchantValidationFailed = (error) => ({
  type: ActionTypes.APPLE_PAY_MERCHANT_VALIDATION_FAILED,
  error,
})

const merchantValidationCompleted = (event) => ({
  type: ActionTypes.APPLE_PAY_MERCHANT_VALIDATION_COMPLETED,
  event,
})

const merchantValidationStarted = () => ({
  type: ActionTypes.APPLE_PAY_MERCHANT_VALIDATION_STARTED,
})

const orderShippingRateChangedSucceeded = (order) => ({
  type: ActionTypes.APPLE_PAY_ORDER_SHIPPING_RATE_CHANGED_SUCCEEDED,
  order,
})

const orderShippingRateChangedFailed = (error) => ({
  type: ActionTypes.APPLE_PAY_ORDER_SHIPPING_RATE_CHANGED_FAILED,
  error,
})

const orderRequestStarted = () => ({
  type: ActionTypes.APPLE_PAY_ORDER_REQUEST_STARTED,
})

const orderRequestCompleted = () => ({
  type: ActionTypes.APPLE_PAY_ORDER_REQUEST_COMPLETED,
})

const orderFatalErrorReceived = (errorStatusCode, error, orderNumber) => ({
  type: ActionTypes.APPLE_PAY_ORDER_FATAL_ERROR_RECEIVED,
  error,
  errorStatusCode,
  orderNumber,
})

const shippingInformationRequestStarted = () => ({
  type: ActionTypes.APPLE_PAY_SHIPPING_INFORMATION_REQUEST_STARTED,
})

const shippingInformationAddFailed = (error) => ({
  type: ActionTypes.APPLE_PAY_SHIPPING_INFORMATION_ADD_FAILED,
  error,
})

const shippingInformationAddSucceeded = (order, isUserlessOrder) => ({
  type: ActionTypes.APPLE_PAY_SHIPPING_INFORMATION_ADD_SUCCEEDED,
  isUserlessOrder,
  order,
})

const submitOrderRequestStarted = () => ({
  type: ActionTypes.APPLE_PAY_SUBMIT_ORDER_REQUEST_STARTED,
})

const orderSubmitCompleteSucceeded = (order) => ({
  type: ActionTypes.APPLE_PAY_ORDER_SUBMIT_COMPLETE_SUCCEEDED,
  order,
})

const orderSubmitCompleteFailed = (error) => ({
  type: ActionTypes.APPLE_PAY_ORDER_SUBMIT_COMPLETE_FAILED,
  error,
})

const orderStepLocationChanged = (redirectPath = "", path = "") => ({
  type: ActionTypes.APPLE_PAY_ORDER_STEP_LOCATION_CHANGED,
  path,
  redirectPath,
})

export const applePayButtonClicked = () => ({
  type: ActionTypes.APPLE_PAY_BUTTON_CLICKED,
})

/* eslint-enable sort-keys */

export const submitApplePayAsync = () => async (dispatch, getState) => {
  dispatch(sessionStarted())

  if (typeof window === "undefined" || !window.ApplePaySession) {
    dispatch(sessionFailed("Your browser does not seem to support Apple Pay."))
    return
  }

  const request = createApplePayRequest(getState)
  const session = new window.ApplePaySession(APPLE_PAY_VERSION, request)

  session.onvalidatemerchant = async (event) => {
    dispatch(merchantValidationStarted())
    try {
      const response = await verify({
        url: event.validationURL,
      })
      const responseData = response.data
      session.completeMerchantValidation(responseData.toJS())
      dispatch(merchantValidationCompleted(responseData))
    } catch (error) {
      dispatch(merchantValidationFailed(error))
    }
  }

  session.onshippingcontactselected = async (event) => {
    const errors = []

    // check if the shipping address country is supported
    const countries = getState().getIn(["location", "countries"])
    const availableCountryCodes = countries.map((country) => country.get("value"))
    addShippingContactErrors(errors, availableCountryCodes, event.shippingContact.countryCode)

    if (errors.length > 0) {
      // return early so to avoid making extra API request if we know we don't
      // support this shipping address country
      session.completeShippingContactSelection({
        errors,
        newLineItems: [],
        newShippingMethods: [],
        newTotal: {
          amount: getState().getIn(["order", "totalNumeric"]),
          label: "Total",
          type: "final",
        },
      })
      return
    }

    const placeholderAddress = getAddressFromEvent(event)
    await dispatch(updateOrderShippingAddress(placeholderAddress, errors))

    const shippingMethods = getState()
      .getIn(["order", "availableShippingRates"])
      .map(convertAvailableShippingRates)

    const shippingCode = shippingMethods.getIn([0, "identifier"])
    await dispatch(updateShippingRate(shippingCode, placeholderAddress))

    const update = {
      errors,
      newLineItems: calcLineItems(getState),
      newShippingMethods: shippingMethods.toJS(),
      newTotal: {
        amount: getState().getIn(["order", "totalNumeric"]),
        label: "Total",
        type: "final",
      },
    }
    session.completeShippingContactSelection(update)
  }

  session.onshippingmethodselected = async (event) => {
    const currentShippingId = getState().getIn(["order", "shippingRate", "code"])
    const selectedShippingMethod = event.shippingMethod
    let newTotal = {
      amount: getState().getIn(["order", "totalNumeric"]),
      label: "Total",
      type: "final",
    }
    let completeShippingMethodSelectionArgs = { newTotal }

    if (selectedShippingMethod.identifier !== currentShippingId) {
      try {
        await dispatch(updateShippingRate(selectedShippingMethod.identifier))
        newTotal = {
          amount: getState().getIn(["order", "totalNumeric"]),
          label: "Total",
          type: "final",
        }
        completeShippingMethodSelectionArgs = {
          newLineItems: calcLineItems(getState),
          newTotal,
        }
      } catch (error) {
        completeShippingMethodSelectionArgs = {
          errors: ["Could not change shipping method"],
          newLineItems: null,
          newTotal: null,
        }
      }
    }
    session.completeShippingMethodSelection(completeShippingMethodSelectionArgs)
  }

  session.onpaymentauthorized = async (event) => {
    await dispatch(submitOrderCompleteAsync(event, session))
  }

  session.begin()
  dispatch(sessionCreated(session))
}

const calcLineItems = (getState) => {
  const shippingRate = getState().getIn(["order", "shippingRate"])
  const tax = getState().getIn(["order", "taxTotalNumeric"])
  const surchargeTotal = getState().getIn(["order", "surchargeTotalNumeric"])

  const shippingTaxLineItems = []
  if (shippingRate && shippingRate.get("totalNumeric")) {
    shippingTaxLineItems.push({
      amount: shippingRate.get("totalNumeric"),
      label: "Shipping",
      type: "final",
    })
  }
  if (tax) {
    shippingTaxLineItems.push({
      amount: tax,
      label: "Tax",
      type: "final",
    })
  }
  if (surchargeTotal) {
    shippingTaxLineItems.push({
      amount: surchargeTotal,
      label: "Surcharge",
      type: "final",
    })
  }

  return getCartLineItems(getState).concat(shippingTaxLineItems)
}

const getCartLineItems = (getState) => {
  return getState()
    .getIn(["cart", "lineItems"])
    .map((lineItem) => {
      return {
        amount: lineItem.get("subtotalNumeric"),
        label: lineItem.get("name"),
        type: "final",
      }
    })
    .toJS()
}

const createApplePayRequest = (getState) => {
  // logged in user does not require email, as it will be populated through
  // account email
  const requiredShippingContactFields = getState().getIn(["auth", "isLoggedIn"])
    ? ["postalAddress", "name", "phone"]
    : ["postalAddress", "name", "phone", "email"]

  return {
    countryCode: APPLE_PAY_COUNTRY_CODE,
    currencyCode: APPLE_PAY_CURRENCY_CODE,
    lineItems: calcLineItems(getState),
    merchantCapabilities: ["supports3DS"],
    requiredBillingContactFields: ["postalAddress"],
    requiredShippingContactFields,
    supportedNetworks: APPLE_PAY_SUPPORTED_NETWORKS,
    total: {
      amount: getState().getIn(["cart", "totalNumeric"]),
      label: "Bonobos Inc.",
    },
  }
}

const updateOrderShippingAddress = (address, errors) => async (dispatch, getState) => {
  dispatch(shippingInformationRequestStarted())
  const isUserless = !getState().getIn(["auth", "isLoggedIn"])

  dispatch(orderRequestStarted())
  const cart = getState().get("cart")
  const number = cart.get("number")
  const token = cart.get("token")

  try {
    const response = await CheckoutApi.addAddress(number, token, address, true)
    const order = response.data.get("cart")
    dispatch(shippingInformationAddSucceeded(order, isUserless))
  } catch (error) {
    if ([401, 404].includes(error.status)) {
      return dispatch(orderFatalErrorReceived(error.status, error.data, number))
    } else {
      dispatch(shippingInformationAddFailed(error.data))

      fillAddressErrorFromApi(errors, error.data)
    }
  }
}

const updateShippingRate =
  (code, inputAddress = null) =>
  async (dispatch, getState) => {
    dispatch(orderRequestStarted())

    const number = getState().getIn(["cart", "number"])
    const token = getState().getIn(["cart", "token"])
    const shippingId = getState().getIn(["order", "shipment", "shipmentId"])
    const newShippingRate = fromJS({
      name: code,
    })

    if (number && token) {
      try {
        const address = inputAddress ? inputAddress : getAddressFromState(getState)
        const response = await CheckoutApi.updateShippingRate(
          number,
          token,
          newShippingRate,
          shippingId,
          address
        )
        dispatch(orderShippingRateChangedSucceeded(response.data.get("cart")))
      } catch (error) {
        if ([401, 404].includes(error.status)) {
          dispatch(orderFatalErrorReceived(error.status, error.data, number))
        } else {
          dispatch(orderShippingRateChangedFailed(error.data))
        }

        throw error
      }
    }

    return dispatch(orderRequestCompleted())
  }

const submitOrderCompleteAsync = (event, session) => async (dispatch, getState) => {
  const order = getState().get("order")
  const number = order.get("number")
  const signifydSessionId = order.get("signifydSessionId")
  const token = order.get("token")

  dispatch(submitOrderRequestStarted())

  if (number && token) {
    try {
      const shippingRate = order.get("shippingRate")
      const ga4ClientId = Cookies.get("_gcl_au")?.replace(/\d\.\d\./g, "")
      const cartData = getCompleteCheckoutDataForRequest(
        event,
        shippingRate,
        signifydSessionId,
        ga4ClientId
      )
      const response = await CheckoutApi.complete(number, token, cartData)

      dispatch(orderSubmitCompleteSucceeded(response.data.get("cart")))
      dispatch(orderStepLocationChanged("confirmation"))

      const result = {
        errors: [],
        status: window.ApplePaySession.STATUS_SUCCESS,
      }
      session.completePayment(result)
      return dispatch(orderRequestCompleted())
    } catch (error) {
      const errorList = []
      fillAddressErrorFromApi(errorList, error.data)

      const result = {
        errors: errorList,
        status: window.ApplePaySession.STATUS_FAILURE,
      }

      if ([401, 404].includes(error.status)) {
        session.completePayment(result)
        return dispatch(orderFatalErrorReceived(error.status, error.data, number))
      } else {
        session.completePayment(result)
        return dispatch(orderSubmitCompleteFailed(error))
      }
    }
  } else {
    // if we are here, it means number && token are null
    const result = {
      errors: [],
      status: window.ApplePaySession.STATUS_FAILURE,
    }
    session.completePayment(result)
    return dispatch(orderSubmitCompleteFailed("Could not retrieve order number or token"))
  }
}

const getAddressFromEvent = (event) => {
  const { administrativeArea, countryCode, locality, postalCode } = event.shippingContact

  return fromJS({
    address1: ADDRESS_PLACEHOLDER,
    address2: "",
    city: locality,
    countryCode: countryCode.toUpperCase(),
    firstName: PERSON_NAME_PLACEHOLDER,
    lastName: PERSON_NAME_PLACEHOLDER,
    phone: PHONE_PLACEHOLDER,
    postalCode,
    regionCode: administrativeArea,
  })
}

const getAddressFromState = (getState) => {
  const orderAddress = getState().getIn(["order", "address"])
  return fromJS({
    address1: ADDRESS_PLACEHOLDER,
    address2: ADDRESS_PLACEHOLDER,
    city: orderAddress.get("city"),
    countryCode: orderAddress.getIn(["country", "code"]),
    firstName: PERSON_NAME_PLACEHOLDER,
    lastName: PERSON_NAME_PLACEHOLDER,
    phone: PHONE_PLACEHOLDER,
    postalCode: orderAddress.getIn(["postalCode", "code"]),
    regionCode: orderAddress.getIn(["region", "code"]),
  })
}
